import { max, comparePrimitives, min, round as round_1 } from "../.fable/fable-library.3.1.12/Util.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.3.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { MotionProp$2 } from "../Wrappers/ReactMotion.fs.js";
import { cons, ofArray } from "../.fable/fable-library.3.1.12/List.js";
import { Motion } from "react-motion";
import { keyValueList } from "../.fable/fable-library.3.1.12/MapUtil.js";
import { initialize, substring } from "../.fable/fable-library.3.1.12/String.js";
import { Helpers_memoEqualsButFunctions } from "../.fable/Fable.React.7.3.0/Fable.React.Helpers.fs.js";

function round(length) {
    return ~(~round_1(length, 1));
}

export const slot = FunctionComponent_Of_Z5A158BBF((props) => {
    const textLen = props.targetText.length | 0;
    if (!props.visible) {
        return react.createElement("span", {
            style: {
                opacity: 0,
            },
        }, props.targetText);
    }
    else {
        const props_3 = ofArray([new MotionProp$2(0, {
            targetLength: 0,
            totalLength: 0,
        }), new MotionProp$2(1, {
            targetLength: {
                damping: 100,
                precision: 0.1,
                stiffness: 400,
                val: textLen,
            },
            totalLength: {
                damping: 100,
                precision: 0.1,
                stiffness: 450,
                val: textLen,
            },
        })]);
        return react.createElement(Motion, keyValueList(cons(["children", (style) => {
            const totalLen = min((x, y) => comparePrimitives(x, y), round(style.totalLength) + 1, textLen) | 0;
            const targetLen = min((x_1, y_1) => comparePrimitives(x_1, y_1), round(style.targetLength) + 1, textLen) | 0;
            return react.createElement("span", {}, substring(props.targetText, 0, targetLen) + ((totalLen === targetLen) ? props.period : initialize(max((x_2, y_2) => comparePrimitives(x_2, y_2), 0, totalLen - targetLen), (i) => {
                const matchValue = props.targetText[i];
                let pattern_matching_result_1, c;
                switch (matchValue) {
                    case "\n": {
                        pattern_matching_result_1 = 0;
                        c = matchValue;
                        break;
                    }
                    case "\r": {
                        pattern_matching_result_1 = 0;
                        c = matchValue;
                        break;
                    }
                    default: pattern_matching_result_1 = 1}
                switch (pattern_matching_result_1) {
                    case 0: {
                        return c;
                    }
                    case 1: {
                        return "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"[round("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".length * Math.random())];
                    }
                }
            })));
        }], props_3), 1));
    }
}, void 0, (x_3, y_3) => Helpers_memoEqualsButFunctions(x_3, y_3), void 0, "slot", "/home/runner/work/ghost-club.github.io/ghost-club.github.io/src/Component/AnimatedText.fs", 15);

