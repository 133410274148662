import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Types_RequestProperties, fetch$ } from "./.fable/Fable.Fetch.2.2.0/Fetch.fs.js";
import { GoogleAppUrl, DataUrl } from "./Properties.fs.js";
import { ofArray } from "./.fable/fable-library.3.1.12/List.js";
import { equals } from "./.fable/fable-library.3.1.12/Util.js";

export function getAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(DataUrl, ofArray([new Types_RequestProperties(0, "GET"), new Types_RequestProperties(3, "cors")])).then(((_arg1) => (_arg1.text().then(((_arg2) => (PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const result = JSON.parse(_arg2, (key, value) => (equals(key, "created") ? (new Date(value)) : value));
        return (result.status === "ok") ? (Promise.resolve(result)) : (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$((GoogleAppUrl + "?action=") + "all", ofArray([new Types_RequestProperties(0, "GET"), new Types_RequestProperties(3, "cors")])).then(((_arg1_1) => (_arg1_1.text().then(((_arg2_1) => (Promise.resolve(JSON.parse(_arg2_1, (key_1, value_2) => (equals(key_1, "created") ? (new Date(value_2)) : value_2)))))))))))));
    }).catch(((_arg3) => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$((GoogleAppUrl + "?action=") + "all", ofArray([new Types_RequestProperties(0, "GET"), new Types_RequestProperties(3, "cors")])).then(((_arg1_2) => (_arg1_2.text().then(((_arg2_2) => (Promise.resolve(JSON.parse(_arg2_2, (key_2, value_4) => (equals(key_2, "created") ? (new Date(value_4)) : value_4))))))))))))))))))))))));
}

