import { Union } from "../.fable/fable-library.3.1.12/Types.js";
import { tuple_type, int32_type, obj_type, lambda_type, unit_type, class_type, anonRecord_type, union_type, bool_type, string_type } from "../.fable/fable-library.3.1.12/Reflection.js";

export class Aria extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Labelledby", "Describedby", "Modal"];
    }
}

export function Aria$reflection() {
    return union_type("ReactModal.Aria", [], Aria, () => [[["Item", string_type]], [["Item", string_type]], [["Item", bool_type]]]);
}

export class Prop extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IsOpen", "PortalClassName", "BodyOpenClassName", "HtmlOpenClassName", "ClassName", "className", "OverlayClassName", "overlayClassName", "AppElement", "OnAfterOpen", "OnAfterClose", "OnRequestClose", "CloseTimeoutMS", "AriaHideApp", "ShouldFocusAfterRender", "ShouldCloseOnOverlayClick", "ShouldCloseOnEsc", "ShouldReturnFocusAfterClose", "PreventScroll", "ParentSelector", "Aria", "Data", "Role", "ContentLabel", "ContentRef", "OverlayRef", "OverlayElement", "ContentElement", "TestId", "id", "CustomItem"];
    }
}

export function Prop$reflection() {
    return union_type("ReactModal.Prop", [], Prop, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", anonRecord_type(["afterOpen", string_type], ["base", string_type], ["beforeClose", string_type])]], [["Item", string_type]], [["Item", anonRecord_type(["afterOpen", string_type], ["base", string_type], ["beforeClose", string_type])]], [["Item", class_type("Browser.Types.HTMLElement")]], [["Item", lambda_type(anonRecord_type(["contentEl", class_type("Browser.Types.HTMLDivElement")], ["overlayEl", class_type("Browser.Types.Element")]), unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(obj_type, unit_type)]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", lambda_type(unit_type, class_type("Browser.Types.HTMLElement"))]], [["Item", Aria$reflection()]], [["Item", obj_type]], [["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.HTMLDivElement"), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.HTMLDivElement"), unit_type)]], [["Item", lambda_type(tuple_type(class_type("System.Collections.Generic.IEnumerable`1", [class_type("Fable.React.Props.IHTMLProp")]), class_type("Fable.React.ReactElement")), class_type("Fable.React.ReactElement"))]], [["Item", lambda_type(tuple_type(class_type("System.Collections.Generic.IEnumerable`1", [class_type("Fable.React.Props.IHTMLProp")]), class_type("Fable.React.ReactElement")), class_type("Fable.React.ReactElement"))]], [["Item", string_type]], [["Item", string_type]], [["Item1", string_type], ["Item2", obj_type]]]);
}

