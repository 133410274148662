import { Union } from "../.fable/fable-library.3.1.12/Types.js";
import { option_type, float64_type, class_type, array_type, anonRecord_type, bool_type, lambda_type, unit_type, obj_type, union_type, string_type, int32_type } from "../.fable/fable-library.3.1.12/Reflection.js";

export class YouTubePlayerVar extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Start", "End", "cc_lang_pref", "Color", "hl", "widget_referrer", "Flag"];
    }
}

export function YouTubePlayerVar$reflection() {
    return union_type("ReactPlayer.YouTubePlayerVar", [], YouTubePlayerVar, () => [[["Item", int32_type]], [["Item", int32_type]], [["lang", string_type]], [["Item", string_type]], [["lang", string_type]], [["domain", string_type]], [["Item1", string_type], ["Item2", int32_type]]]);
}

export class YouTubeConfig extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmbedOptions", "OnUnstarted", "ErasedKVP"];
    }
}

export function YouTubeConfig$reflection() {
    return union_type("ReactPlayer.YouTubeConfig", [], YouTubeConfig, () => [[["Item", obj_type]], [["Item", lambda_type(unit_type, unit_type)]], [["Item1", string_type], ["Item2", obj_type]]]);
}

export class VimeoOption extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Autopause", "Background", "Byline", "Color", "Dnt", "Portrait", "Quality", "Responsive", "Speed", "TextTrack", "Title", "Transparent"];
    }
}

export function VimeoOption$reflection() {
    return union_type("ReactPlayer.VimeoOption", [], VimeoOption, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["hex", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["lang", string_type]], [["Item", bool_type]], [["Item", bool_type]]]);
}

export class VimeoConfig extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ErasedKVP"];
    }
}

export function VimeoConfig$reflection() {
    return union_type("ReactPlayer.VimeoConfig", [], VimeoConfig, () => [[["Item1", string_type], ["Item2", obj_type]]]);
}

export class Config extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ErasedKVP"];
    }
}

export function Config$reflection() {
    return union_type("ReactPlayer.Config", [], Config, () => [[["Item1", string_type], ["Item2", obj_type]]]);
}

export class ReactPlayerProp_Common$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Url", "url", "url", "url", "Playing", "Loop", "Controls", "Light", "light", "Volume", "Muted", "PlaybackRate", "ProgressInterval", "Playsinline", "Pip", "StopOnUnmount", "Fallback", "Wrapper", "PlayIcon", "PreviewTabIndex", "OnReady", "OnStart", "OnPlay", "OnPause", "OnBuffer", "OnBufferEnd", "OnEnded", "OnClickPreview", "OnEnablePIP", "OnDisablePIP", "OnError", "OnDuration", "OnSeek", "OnProgress", "Key", "Ref", "RefValue", "Custom"];
    }
}

export function ReactPlayerProp_Common$1$reflection(gen0) {
    return union_type("ReactPlayer.ReactPlayerProp.Common`1", [gen0], ReactPlayerProp_Common$1, () => [[["Item", string_type]], [["Item", array_type(anonRecord_type(["src", string_type], ["type", string_type]))]], [["Item", array_type(string_type)]], [["Item", class_type("Browser.Types.MediaStream")]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["url", string_type]], [["Item", float64_type]], [["Item", bool_type]], [["Item", float64_type]], [["ms", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", class_type("Fable.React.ReactElement")]], [["Item", string_type]], [["Item", obj_type]], [["Item", int32_type]], [["Item", lambda_type(class_type("ReactPlayer.ReactPlayer"), unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(obj_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(obj_type, unit_type)]], [["Item", lambda_type(float64_type, unit_type)]], [["Item", lambda_type(float64_type, unit_type)]], [["Item", lambda_type(anonRecord_type(["loaded", float64_type], ["loadedSeconds", float64_type], ["played", float64_type], ["playedSeconds", float64_type]), unit_type)]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.Element"), unit_type)]], [["Item", class_type("Fable.React.IRefValue`1", [option_type(class_type("Browser.Types.Element"))])]], [["Item1", string_type], ["Item2", obj_type]]]);
}

