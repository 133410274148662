import { Union } from "../.fable/fable-library.3.1.12/Types.js";
import { tuple_type, string_type, option_type, obj_type, anonRecord_type, lambda_type, unit_type, float64_type, union_type, class_type, bool_type } from "../.fable/fable-library.3.1.12/Reflection.js";

export class CommonProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Appear", "Enter", "Exit", "CustomProp"];
    }
}

export function CommonProp$reflection() {
    return union_type("ReactTransitionGroup.CommonProp", [], CommonProp, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", class_type("Fable.React.Props.IHTMLProp")]]]);
}

export class CommonTransitionProp$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["timeout", "addEndListener", "In", "MountOnEnter", "UnmountOnExit", "NodeRef"];
    }
}

export function CommonTransitionProp$1$reflection(gen0) {
    return union_type("ReactTransitionGroup.CommonTransitionProp`1", [gen0], CommonTransitionProp$1, () => [[["Item", float64_type]], [["Item", anonRecord_type(["explicit", lambda_type(lambda_type(unit_type, unit_type), unit_type)], ["implicit", lambda_type(class_type("Browser.Types.HTMLElement"), lambda_type(lambda_type(unit_type, unit_type), unit_type))])]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", option_type(obj_type)]]]);
}

export class TransitionProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnEnter", "OnEntering", "OnEntered", "OnExit", "OnExiting", "OnExited"];
    }
}

export function TransitionProp$reflection() {
    return union_type("ReactTransitionGroup.TransitionProp", [], TransitionProp, () => [[["Item", anonRecord_type(["explicit", lambda_type(bool_type, unit_type)], ["implicit", lambda_type(class_type("Browser.Types.HTMLElement"), lambda_type(bool_type, unit_type))])]], [["Item", anonRecord_type(["explicit", lambda_type(bool_type, unit_type)], ["implicit", lambda_type(class_type("Browser.Types.HTMLElement"), lambda_type(bool_type, unit_type))])]], [["Item", anonRecord_type(["explicit", lambda_type(bool_type, unit_type)], ["implicit", lambda_type(class_type("Browser.Types.HTMLElement"), lambda_type(bool_type, unit_type))])]], [["Item", anonRecord_type(["explicit", lambda_type(unit_type, unit_type)], ["implicit", lambda_type(class_type("Browser.Types.HTMLElement"), unit_type)])]], [["Item", anonRecord_type(["explicit", lambda_type(unit_type, unit_type)], ["implicit", lambda_type(class_type("Browser.Types.HTMLElement"), unit_type)])]], [["Item", anonRecord_type(["explicit", lambda_type(unit_type, unit_type)], ["implicit", lambda_type(class_type("Browser.Types.HTMLElement"), unit_type)])]]]);
}

export class Class extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Appear", "AppearActive", "AppearDone", "Enter", "EnterActive", "EnterDone", "Exit", "ExitActive", "ExitDone"];
    }
}

export function Class$reflection() {
    return union_type("ReactTransitionGroup.Class", [], Class, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export class CSSTransitionProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["classNames", "OnEnter", "OnEntering", "OnExit", "OnExiting"];
    }
}

export function CSSTransitionProp$reflection() {
    return union_type("ReactTransitionGroup.CSSTransitionProp", [], CSSTransitionProp, () => [[["Item", string_type]], [["Item", lambda_type(tuple_type(class_type("Browser.Types.HTMLElement"), bool_type), unit_type)]], [["Item", lambda_type(tuple_type(class_type("Browser.Types.HTMLElement"), bool_type), unit_type)]], [["Item", lambda_type(tuple_type(class_type("Browser.Types.HTMLElement"), bool_type), unit_type)]], [["Item", lambda_type(tuple_type(class_type("Browser.Types.HTMLElement"), bool_type), unit_type)]]]);
}

export class Timeout extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Appear", "Enter", "Exit"];
    }
}

export function Timeout$reflection() {
    return union_type("ReactTransitionGroup.Timeout", [], Timeout, () => [[["Item", float64_type]], [["Item", float64_type]], [["Item", float64_type]]]);
}

class TransitionChildrenFunc extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Children"];
    }
}

function TransitionChildrenFunc$reflection() {
    return union_type("ReactTransitionGroup.TransitionChildrenFunc", [], TransitionChildrenFunc, () => [[["Item", lambda_type(string_type, class_type("Fable.React.ReactElement"))]]]);
}

export class TransitionGroupProp$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Component", "ChildFactory"];
    }
}

export function TransitionGroupProp$1$reflection(gen0) {
    return union_type("ReactTransitionGroup.TransitionGroupProp`1", [gen0], TransitionGroupProp$1, () => [[["Item", gen0]], [["Item", lambda_type(class_type("Fable.React.ReactElement"), class_type("Fable.React.ReactElement"))]]]);
}

