import { Record, Union } from "./.fable/fable-library.3.1.12/Types.js";
import { bool_type, record_type, array_type, anonRecord_type, int32_type, string_type, union_type, list_type, class_type } from "./.fable/fable-library.3.1.12/Reflection.js";
import { ofList, empty } from "./.fable/fable-library.3.1.12/Set.js";
import { comparePrimitives } from "./.fable/fable-library.3.1.12/Util.js";
import { singleton } from "./.fable/fable-library.3.1.12/List.js";

export class ModelState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Loading", "Loaded", "Error"];
    }
}

export function ModelState$reflection() {
    return union_type("Model.ModelState", [], ModelState, () => [[], [], [["Item", list_type(class_type("System.Exception"))]]]);
}

export class Model extends Record {
    constructor(state, api, lang, completed, flags) {
        super();
        this.state = state;
        this.api = api;
        this.lang = lang;
        this.completed = completed;
        this.flags = flags;
    }
}

export function Model$reflection() {
    return record_type("Model.Model", [], Model, () => [["state", ModelState$reflection()], ["api", class_type("Api.IResult`1", [anonRecord_type(["images", array_type(anonRecord_type(["baseUrl", string_type], ["created", class_type("System.DateTimeOffset")], ["height", int32_type], ["id", string_type], ["mimeType", string_type], ["origUrl", string_type], ["origUrlWebP", string_type], ["srcSet", string_type], ["srcSetWebP", string_type], ["thumbnailUrl", string_type], ["thumbnailUrlWebP", string_type], ["width", int32_type]))], ["poems", array_type(class_type("Api.IPoem"))])])], ["lang", string_type], ["completed", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])], ["flags", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ignore", "CheckInitTaskDone", "CheckAnchorAndJump", "InitError", "TryLoadApiAgain", "LoadApiResponse", "SwitchLanguage", "Completed", "SetFlag", "TriggerAfter"];
    }
}

export function Msg$reflection() {
    return union_type("Model.Msg", [], Msg, () => [[], [], [], [["Item", class_type("System.Exception")]], [], [["Item", class_type("Api.IResult`1", [anonRecord_type(["images", array_type(anonRecord_type(["baseUrl", string_type], ["created", class_type("System.DateTimeOffset")], ["height", int32_type], ["id", string_type], ["mimeType", string_type], ["origUrl", string_type], ["origUrlWebP", string_type], ["srcSet", string_type], ["srcSetWebP", string_type], ["thumbnailUrl", string_type], ["thumbnailUrlWebP", string_type], ["width", int32_type]))], ["poems", array_type(class_type("Api.IPoem"))])])]], [["Item", string_type]], [["Item", string_type]], [["Item1", string_type], ["Item2", bool_type]], [["ms", int32_type], ["Item2", Msg$reflection()]]]);
}

export function initModel(arg) {
    return new Model(new ModelState(0), {
        status: "loading",
    }, "unspecified", empty({
        Compare: (x, y) => comparePrimitives(x, y),
    }), ofList(singleton("playButtonIsShown"), {
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    }));
}

