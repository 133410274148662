import { tap, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.0.0/Promise.fs.js";
import react$002Dmodal from "react-modal";
import { promise } from "./.fable/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Model, ModelState, initModel, Msg } from "./Model.fs.js";
import smoothscroll$002Dpolyfill from "smoothscroll-polyfill";
import { some } from "./.fable/fable-library.3.1.12/Option.js";
import { stringHash, jsOptions } from "./.fable/fable-library.3.1.12/Util.js";
import translation from "./locales/ja/translation.json";
import translation_1 from "./locales/en/translation.json";
import { DomainNameInPunyCode } from "./Properties.fs.js";
import i18next from "i18next";
import i18next$002Dbrowser$002Dlanguagedetector from "i18next-browser-languagedetector";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.1.12/Seq.js";
import { Screen } from "./.fable/Fulma.2.10.0/Common.fs.js";
import { getAll } from "./Api.fs.js";
import { Cmd_OfPromise_perform, Cmd_OfFunc_result, Cmd_none, Cmd_ofSub, Cmd_OfPromise_result, Cmd_batch } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { toArray, singleton as singleton_1, cons, contains, ofArray } from "./.fable/fable-library.3.1.12/List.js";
import { printf, toConsoleError, trimStart } from "./.fable/fable-library.3.1.12/String.js";
import { toString } from "./.fable/fable-library.3.1.12/Types.js";
import { remove, add, contains as contains_1 } from "./.fable/fable-library.3.1.12/Set.js";
import * as react from "react";
import { view as view_1 } from "./View/Header.fs.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./.fable/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";

export const initReactModalTask = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
    react$002Dmodal.setAppElement(document.getElementById("#root"));
    return Promise.resolve((new Msg(0)));
}));

export const initSmoothScrollPolyfillTask = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
    smoothscroll$002Dpolyfill.polyfill();
    return Promise.resolve((new Msg(0)));
}));

export const initI18nTask = (() => {
    const options = {
        supportedLngs: ["ja", "en"],
        ns: some(["translation"]),
        fallbackLng: some("en"),
        returnEmptyString: false,
        resources: jsOptions((it_1) => {
            it_1["ja"]={
                translation: translation,
            };
            it_1["en"]={
                translation: translation_1,
            };
        }),
        detection: some(jsOptions((it_2) => {
            it_2.cookieDomain = DomainNameInPunyCode;
            it_2.cookieMinutes = 10;
        })),
    };
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (i18next.use(i18next$002Dbrowser$002Dlanguagedetector).init(options).then(((_arg1) => {
        const lang = (i18next.language === "ja") ? "ja" : "en";
        return Promise.resolve((new Msg(6, lang)));
    })))));
})();

export function changeLanguageTask(langCode) {
    return i18next.changeLanguage(langCode).then((_arg1) => {
        const langAttr = document.createAttribute("lang");
        langAttr.value = langCode;
        void document.documentElement.attributes.setNamedItem(langAttr);
        return new Msg(0);
    });
}

let initApiCompleted = false;

let initApiRetried = false;

export function initApiTask() {
    return Promise.race(toList(delay(() => append((!initApiRetried) ? singleton(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, 5000))).then((() => {
        let screen;
        if ((!initApiCompleted) ? (!initApiRetried) : false) {
            initApiRetried = true;
            return Promise.resolve((new Msg(4)));
        }
        else if (initApiRetried) {
            if (screen = (new Screen(3)), (screen.tag === 5) ? (window.matchMedia("(max-width: 1023.99px)")).matches : ((screen.tag === 3) ? (window.matchMedia("(max-width: 768.99px)")).matches : ((screen.tag === 2) ? (window.matchMedia("(min-width: 769px)")).matches : ((screen.tag === 1) ? (window.matchMedia("(min-width: 1024px)")).matches : ((screen.tag === 4) ? (window.matchMedia("(min-width: 1216px)")).matches : ((screen.tag === 6) ? (window.matchMedia("(min-width: 1408px)")).matches : true)))))) {
                return Promise.resolve((new Msg(5, {
                    message: "API timed out",
                    status: "error",
                })));
            }
            else {
                window.location.reload(true);
                return Promise.resolve((new Msg(0)));
            }
        }
        else {
            return Promise.resolve((new Msg(0)));
        }
    })))))) : empty(), delay(() => {
        let pr_1, pr;
        return singleton((pr_1 = (pr = tap((_arg1_1) => {
            initApiCompleted = true;
        }, getAll()), pr.then(((arg0) => (new Msg(5, arg0))))), pr_1.then(void 0, ((e) => (new Msg(5, {
            message: e.message,
            status: "error",
        }))))));
    })))));
}

export function checkWebSub(dispatch) {
    const img = new Image();
    img.src = "data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==";
    img.onload = ((_arg1) => {
        dispatch(new Msg(8, "canUseWebP", (img.width > 0) ? (img.height > 0) : false));
        dispatch(new Msg(7, "webPCheckDone"));
    });
    img.onerror = ((_arg2) => {
        dispatch(new Msg(8, "canUseWebP", false));
        dispatch(new Msg(7, "webPCheckDone"));
    });
}

export const initCmd = Cmd_batch(ofArray([Cmd_OfPromise_result(initReactModalTask.then(void 0, ((arg0) => (new Msg(3, arg0))))), Cmd_OfPromise_result(initSmoothScrollPolyfillTask.then(void 0, ((arg0_1) => (new Msg(3, arg0_1))))), Cmd_OfPromise_result(initI18nTask.then(void 0, ((arg0_2) => (new Msg(3, arg0_2))))), Cmd_OfPromise_result((() => {
    const pr_3 = initApiTask();
    return pr_3.then(void 0, ((arg0_3) => (new Msg(3, arg0_3))));
})()), Cmd_ofSub((dispatch) => {
    checkWebSub(dispatch);
})]));

export function delayCmd(ms, msg) {
    return Cmd_OfPromise_result(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, ms))).then((() => (Promise.resolve(msg))))))));
}

const validHash = ofArray(["about", "how-to-join", "dj-mix", "gallery", "contact", "credits"]);

export function scrollToAnchorCmd() {
    return Cmd_OfPromise_result(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if (window.location.hash == null) {
            return Promise.resolve((new Msg(0)));
        }
        else {
            const hash = trimStart(window.location.hash, "#");
            if (contains(hash, validHash, {
                Equals: (x, y) => (x === y),
                GetHashCode: (x) => stringHash(x),
            })) {
                const target = document.getElementById(hash);
                if (target == null) {
                    return Promise.resolve((new Msg(0)));
                }
                else {
                    target.scrollIntoView({
                        behavior: "smooth",
                    });
                    return Promise.resolve((new Msg(0)));
                }
            }
            else {
                return Promise.resolve((new Msg(0)));
            }
        }
    })));
}

export function init(arg) {
    return [initModel(arg), initCmd];
}

export function update(msg, model) {
    let matchValue;
    if (msg.tag === 9) {
        return [model, delayCmd(msg.fields[0], msg.fields[1])];
    }
    else if (msg.tag === 3) {
        const arg10 = toString(msg.fields[0]);
        toConsoleError(printf("error on initialization:\n%s"))(arg10);
        return [new Model((matchValue = model.state, (matchValue.tag === 2) ? (new ModelState(2, cons(msg.fields[0], matchValue.fields[0]))) : (new ModelState(2, singleton_1(msg.fields[0])))), model.api, model.lang, model.completed, model.flags), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [model, scrollToAnchorCmd()];
    }
    else if (msg.tag === 1) {
        const patternInput = (model.state.tag === 0) ? (((((model.lang === "unspecified") ? true : (model.api.status === "loading")) ? true : (!contains_1("logoShown", model.completed))) ? true : (!contains_1("webPCheckDone", model.completed))) ? [model, Cmd_none()] : [new Model(new ModelState(1), model.api, model.lang, model.completed, model.flags), Cmd_OfFunc_result(new Msg(9, 1000, new Msg(2)))]) : [model, Cmd_none()];
        return [patternInput[0], patternInput[1]];
    }
    else if (msg.tag === 7) {
        return [new Model(model.state, model.api, model.lang, add(msg.fields[0], model.completed), model.flags), Cmd_OfFunc_result(new Msg(1))];
    }
    else if (msg.tag === 6) {
        return [new Model(model.state, model.api, msg.fields[0], model.completed, model.flags), Cmd_batch(ofArray([(msg.fields[0] === "en") ? Cmd_OfPromise_perform((langCode) => changeLanguageTask(langCode), "en", (x_3) => x_3) : ((msg.fields[0] === "ja") ? Cmd_OfPromise_perform((langCode_1) => changeLanguageTask(langCode_1), "ja", (x_4) => x_4) : Cmd_none()), Cmd_OfFunc_result(new Msg(1))]))];
    }
    else if (msg.tag === 4) {
        return [model, Cmd_OfPromise_result(initApiTask())];
    }
    else if (msg.tag === 5) {
        return [new Model(model.state, msg.fields[0], model.lang, model.completed, model.flags), Cmd_OfFunc_result(new Msg(1))];
    }
    else if (msg.tag === 8) {
        if (msg.fields[1]) {
            return [new Model(model.state, model.api, model.lang, model.completed, add(msg.fields[0], model.flags)), Cmd_none()];
        }
        else {
            return [new Model(model.state, model.api, model.lang, model.completed, remove(msg.fields[0], model.flags)), Cmd_none()];
        }
    }
    else {
        return [model, Cmd_none()];
    }
}

export const loaded = (() => {
    const fallback = react.createElement("div", {});
    const elemType = react.lazy(() => import("./LazyLoadedViews.fs.js").then((m) => m.view).then((x) => ({
        default: x,
    })));
    return (props_2) => react.createElement(react.Suspense, {
        fallback: fallback,
    }, react.createElement(elemType, props_2));
})();

function view(model, dispatch) {
    return toArray(toList(delay(() => append(singleton(view_1({
        completed: model.completed,
        dispatch: dispatch,
        flags: model.flags,
        state: model.state,
    })), delay(() => ((model.state.tag === 1) ? singleton(loaded({
        api: model.api,
        dispatch: dispatch,
        flags: model.flags,
        lang: model.lang,
    })) : singleton(null)))))));
}

ProgramModule_run(Program_withReactSynchronous("root", ProgramModule_mkProgram(init, (msg, model) => update(msg, model), (model_1, dispatch) => view(model_1, dispatch))));

