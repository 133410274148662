import { Union } from "../.fable/fable-library.3.1.12/Types.js";
import { array_type, anonRecord_type, string_type, lambda_type, unit_type, union_type, float64_type, int32_type } from "../.fable/fable-library.3.1.12/Reflection.js";

export class SpringHelperConfig extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Stiffness", "Damping", "Precision"];
    }
}

export function SpringHelperConfig$reflection() {
    return union_type("ReactMotion.SpringHelperConfig", [], SpringHelperConfig, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", float64_type]]]);
}

export class MotionProp$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DefaultStyle", "Style", "OnRest"];
    }
}

export function MotionProp$2$reflection(gen0, gen1) {
    return union_type("ReactMotion.MotionProp`2", [gen0, gen1], MotionProp$2, () => [[["Item", gen1]], [["Item", gen0]], [["Item", lambda_type(unit_type, unit_type)]]]);
}

export class TransitionStyle$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Key", "Data", "Style"];
    }
}

export function TransitionStyle$2$reflection(gen0, gen1) {
    return union_type("ReactMotion.TransitionStyle`2", [gen0, gen1], TransitionStyle$2, () => [[["Item", string_type]], [["Item", gen0]], [["Item", gen1]]]);
}

export class TransitionProp$3 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DefaultStyles", "Styles", "styles", "WillEnter", "WillLeave", "DidLeave"];
    }
}

export function TransitionProp$3$reflection(gen0, gen1, gen2) {
    return union_type("ReactMotion.TransitionProp`3", [gen0, gen1, gen2], TransitionProp$3, () => [[["Item", anonRecord_type(["data", gen0], ["key", string_type], ["style", gen2])]], [["Item", array_type(TransitionStyle$2$reflection(gen0, gen2))]], [["Item", lambda_type(array_type(anonRecord_type(["data", gen0], ["key", string_type], ["style", gen2])), array_type(TransitionStyle$2$reflection(gen0, gen2)))]], [["Item", lambda_type(TransitionStyle$2$reflection(gen0, gen2), gen2)]], [["Item", lambda_type(TransitionStyle$2$reflection(gen0, gen2), gen1)]], [["Item", lambda_type(TransitionStyle$2$reflection(gen0, gen2), unit_type)]]]);
}

export class StaggeredMotionProp$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DefaultStyles", "Styles"];
    }
}

export function StaggeredMotionProp$2$reflection(gen0, gen1) {
    return union_type("ReactMotion.StaggeredMotionProp`2", [gen0, gen1], StaggeredMotionProp$2, () => [[["Item", array_type(gen1)]], [["Item", lambda_type(array_type(gen1), array_type(gen0))]]]);
}

